<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">
            <span><img src="{{logo}}" height="32" width="auto" style="margin-right: 10px;" /></span>

        </a>

    </div>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container overflow">

    <nb-actions size="small">

        <!-- <nb-action>
      <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
      </nb-select>
    </nb-action> -->
        <nb-action *ngIf="activeusername!==null">
            <span (click)="gotoStart()">
                <nb-icon icon="person-outline" class="userico"></nb-icon> {{activeusername}}
            </span>
        </nb-action>

        <nb-action>
            <div class="internet">
                <nb-icon title="internet available" *ngIf="internetStatus" icon="wifi-outline" style="color: green !important;" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                <nb-icon title="internet not available" *ngIf="!internetStatus" icon="wifi-off-outline" style="color: red !important;" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
            </div>

            <div class="sync">

                <div class="syncstatus">
                    <div class="status-label">{{sync_status}}</div>
                    <div class="status-time">{{updated_time|date:'short'}}</div>
                </div>
                <div [nbSpinner]="loading||loading1" nbSpinnerStatus="success">
                    <span title="sync data to pos" style="cursor: pointer; color: white!important;" (click)="forcePush()">
                        <nb-icon icon="sync-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                    </span>
                </div>
            </div>
        </nb-action>


        <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user [nbContextMenu]="userMenu" [onlyPicture]="false" [name]="user?.name" [picture]="user?.picture">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>