import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-weekday-picker',
  templateUrl: './weekday-picker.component.html',
  styleUrls: ['./weekday-picker.component.scss']
})
export class WeekdayPickerComponent implements OnInit {

  constructor(private ref:NbDialogRef<WeekdayPickerComponent>) { }
  mon: boolean = true;
  tue: boolean = true;
  wed: boolean = true;
  thu: boolean = true;
  fri: boolean = true;
  sat: boolean = true;
  sun: boolean = true;
  @Input() title: string
  ngOnInit(): void {
  }


update(){
  console.log(this.mon, this.tue, this.wed, this.thu, this.fri, this.sat, this.sun);
}

close(){
  this.ref.close();
}

}
