import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by" *ngIf="environment.product=='SIPO'">
     <b><a href="https://sipocloudpos.com" target="_blank">SIPO CLOUD POS</a></b> 
    </span>
    <span class="created-by" *ngIf="environment.product=='SILVERBACK'">
      Created with ♥ by <b><a href="https://outlogics.com" target="_blank">OUTLOGICS</a></b> 2022
    </span>
    <span> Version: {{environment.version}}</span>
    <div class="socials">
      
      <a *ngIf="environment.product=='SILVERBACK'" href="https://www.facebook.com/outlogics" target="_blank" class="ion ion-social-facebook"></a>
      <a *ngIf="environment.product=='SIPO'" href="https://www.facebook.com/sipocloudpos" target="_blank" class="ion ion-social-facebook"></a>
     
      
    </div>
  `,
})
export class FooterComponent {
  environment = environment;
}
