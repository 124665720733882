import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-email-id',
  templateUrl: './email-id.component.html',
  styleUrls: ['./email-id.component.scss']
})
export class EmailIdComponent implements OnInit {

  constructor(private ref:NbDialogRef<EmailIdComponent>) { }
  @Input()data:any;
  mailId:any;
  invalid:boolean=false;
  invalidMsg:string='';
  ngOnInit(): void {
  }

  close(status){
    if(status){
      if(this.mailId==null ||this.mailId== undefined )
      {
        this.invalid=true;
        this.invalidMsg="Email id can't be empty !!"
      }
      else if(this.mailId.length>0)
      {// remove whitespace 
        this.mailId = this.mailId.trim();
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isValidEmail = emailRegex.test(this.mailId);
        if (!isValidEmail) {
          this.invalid=true;
          this.invalidMsg="Invalid Email ID !!"
        } 
        else {
          this.ref.close({status:status,mail:this.mailId})
        }
      }
    }
    else{
      this.ref.close({status:status,mail:this.mailId})
    }
  }

}
