<nb-card>
    <nb-card-header>
        Weekday Picker
    </nb-card-header>
    <nb-card-body>
        <div class="dayslist">
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="mon">Mon</nb-checkbox>
            </div>
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="tue">Tue</nb-checkbox>
            </div>
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="wed">Wed</nb-checkbox>
            </div>
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="thu">Thu</nb-checkbox>
            </div>
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="fri">Fri</nb-checkbox>
            </div>
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="sat">Sat</nb-checkbox>
            </div>
            <div class="dayitem">
                <nb-checkbox [(ngModel)]="sun">Sun</nb-checkbox>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="primary" (click)="update()"> SELECT</button>
        <button nbButton status="danger"> CANCEL</button>
    </nb-card-footer>
</nb-card>