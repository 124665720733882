<nb-card class="card-box">

    <nb-card-header class="card-header">
        <h6>File Viewer</h6>
        <button nbButton status="danger" (click)="close()">Close</button>
    </nb-card-header>
    <nb-card-body class="card-body">
        <img *ngIf="data.type=='IMAGE'" [src]="data.url" alt="image">
    </nb-card-body>



</nb-card>