<nb-card class="c-body">
    <nb-card-header>
        Availability
    </nb-card-header>
    <nb-card-body>

        <button nbButton class="btn mb-3" (click)="addNew()">ADD NEW TIMINGS</button>




        <table class="table table-bordered" *ngIf="availability?.length>0">
            <tr>
                <th>#</th>
                <th>Domain</th>
                <th>Days</th>
                <th>Hours</th>

                <th>Dates</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let item of availability ; index as i">
                <td>{{i+1}}</td>
                <td>
                    <div class="t-items-flex" *ngIf=" item?.DM&&item?.DM.length>0"><span class="t-items" *ngFor="let domain of item?.DM ; index as i">{{domain}}</span></div>
                </td>
                <td>
                    <div class="t-items-flex" *ngIf=" item?.D&&item?.D.length>0"><span class="t-items" *ngFor="let day of item?.D ; index as i">{{getDay(day)}}</span></div>
                </td>
                <td>
                    <div class="t-items-flex" *ngIf=" item?.H&&item?.H.length>0"><span class="t-items" *ngFor="let hour of item?.H ; index as i">{{hour.S}}-{{hour.E}}</span></div>

                </td>

                <td><span *ngIf="item?.SD&&item?.ED">{{item.SD}} - {{item.ED}}</span></td>
                <td>
                    <button class="btn-edit" status="primary" (click)="edit(item,i)"><i class="nb-edit"></i></button>
                    <button class="btn-rem" status="danger" (click)="remove(i)"><i class="nb-trash"></i></button>
                </td>

            </tr>
        </table>

        <div *ngIf="availability?.length==0">
            <p>No availability timings found</p>
        </div>

        <!-- <div class="availability-item" *ngFor="let item of availability;index as i">

            <div class="item-box" *ngIf="item?.domain?.length>0">
                <label for="domain" class="label">Domain</label>
                <div class="item-layout">
                    <div class="item" *ngFor="let domain of item.domain">{{domain}}</div>
                </div>
            </div>
            <div class="item-box" *ngIf="item?.days?.length>0">
                <label for="days" class="label">Days</label>
                <div class="item-layout" *ngIf="item?.days?.length>0">
                    <div class="item" *ngFor="let day of item.days">{{getDay(day)}}</div>
                </div>
            </div>

            <div class="item-box" *ngIf="item?.hours?.length>0">
                <label for="days" class="label">Hours</label>
                <div class="item-layout" *ngIf="item?.hours?.length>0">
                    <div class="item" *ngFor="let hour of item.hours">{{hour.Start}} - {{hour.End}}</div>
                </div>
            </div>

            <div class="item-box" *ngIf="item?.startDate && item?.endDate">
                <label for="days" class="label">Date Range</label>
                <div class="item" *ngIf="item?.startDate && item?.endDate">{{item.startDate}} - {{item.endDate}}</div>
            </div>

            <div class="action">
                <button nbButton hero status="primary" (click)="edit(item)">EDIT</button>
                <button nbButton hero status="danger" (click)="remove(i)">Remove</button>
            </div>
        </div> -->

    </nb-card-body>
    <nb-card-footer>

        <button nbButton type="button" status="danger" (click)="close(false)">CANCEL</button>
        <button nbButton type="button" class="mx-2" status="success" (click)="save()">SAVE</button>
    </nb-card-footer>
</nb-card>