import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'ngx-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {

  @Input() title;
  @Input() message;
  invalidPassword: boolean = false;
  @ViewChild('password', { static: false }) password: ElementRef;
  constructor(protected ref: NbDialogRef<ConfirmPasswordComponent>, private service: ApiServiceService) { }

  ngOnInit(): void {



  }

  checkPassword(password) {
    let data = {
      "password": password
    }
    this.service.confirmPassword(data).subscribe((res: any) => {
      if (res.password) {
        this.ref.close(true);
      }
      else {
        this.invalidPassword = true;
      }
    })
  }

  closetrue() {
    this.ref.close(true);
  }

  check() {
    this.checkPassword(this.password.nativeElement.value);
  }

  close() {
    this.ref.close(false);
  }

}
