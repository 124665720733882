import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  constructor(private ref:NbDialogRef<FileViewerComponent>,) { }
  @Input() data;
  ngOnInit(): void {
  }

  close(){
    this.ref.close();
  }

}
