import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiServiceService } from '../../api-service.service';


@Component({
  selector: 'ngx-availability-form',
  templateUrl: './availability-form.component.html',
  styleUrls: ['./availability-form.component.scss']
})
export class AvailabilityFormComponent implements OnInit {

  constructor(private ref:NbDialogRef<AvailabilityFormComponent>,private api:ApiServiceService) { }
  availability:any=[];
  form:any={};
  fromtime:any;
  totime:any;
  daterange:any;

  @Input() title: any;
  @Input() type: any;
  @Input() data: any;
  @Input() index: any;

  ngOnInit(): void {

    if(this.type === 'edit')
    {
      console.log('data :',this.data)
     this.form = this.data;
     if(this.data.hasOwnProperty('SD')&& this.data.hasOwnProperty('ED')){
      this.setDateRange(this.data.SD,this.data.ED);
     }
  
    }


  }

  close(status){
    this.ref.close({status:false,data:null});
  }

  toggleDomain(checked: boolean,domain:any) {
    if (!this.form.hasOwnProperty('DM')) {
      this.form['DM'] = []; // Initialize domain as an empty array if it doesn't exist
  }

  checked ? addDomain.call(this, domain) : removeDomain.call(this, domain);
  
    function addDomain(domain){
      this.form['DM'].push(domain); 
    }
    function removeDomain(domain){
      const index = this.form['DM'].indexOf(domain);
      if (index > -1) {
        this.form['DM'].splice(index, 1);
      }
    }

    if(this.form.DM.length<1){
      delete this.form['DM']; 
    } 
    console.log(this.form);
  }


  toggleDay(checked: boolean,day:any) {
    if (!this.form.hasOwnProperty('D')) {
      this.form['D'] = []; // Initialize domain as an empty array if it doesn't exist
  }
  checked ? addDay.call(this, day) : removeDay.call(this, day);
    function addDay(day){
      this.form['D'].push(day); 
    }
    function removeDay(day){
      const index = this.form['D'].indexOf(day);
      if (index > -1) {
        this.form['D'].splice(index, 1);
      }
    }

    if(this.form.D.length<1){
      delete this.form['D']; 
    } 
    console.log(this.form);
  }


  addTime(){
    if (!this.form.hasOwnProperty('H')) {
      this.form['H'] = []; // Initialize domain as an empty array if it doesn't exist
    }

    if(this.fromtime && this.totime){
      this.form['H'].push({'S':this.fromtime,'E':this.totime});
      this.fromtime=null;
      this.totime=null;
    }
    else{
      if(this.form.H.length<1){
        delete this.form['H']; 
      } 
    }

    console.log(this.form);
  }

  removeTime(i){
    this.form['H'].splice(i, 1);
    if(this.form.H.length<1){
      delete this.form['H']; 
    } 
    console.log(this.form);
  }

  changeDate(event){
    console.log("date range: ",event);
    this.form['SD']=this.formatDate(event.start); //event.start;
    this.form['ED']=this.formatDate(event.end); //event.end;
    console.log('check Point',this.form);
  }


  setDateRange(startDate: string, endDate: string) {
    console.log(startDate, endDate);

    this.daterange={ start: new Date(startDate), end: new Date(endDate) };
    console.log(this.daterange);

  }

formatDate(datestring) {
  const sdate = new Date(datestring);
  const year = sdate.getFullYear();
  const month = String(sdate.getMonth() + 1).padStart(2, '0');
  const day = String(sdate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  console.log(formattedDate);
  return formattedDate;
}


save(){
  let count=0;
  if(this.form){

    if(this.form.hasOwnProperty('SD')&& this.form.hasOwnProperty('ED')&& this.form.SD && this.form.ED){
      count++;
    }
    if(this.form.hasOwnProperty('H') && this.form.H.length>0){
      count++;
    }
    if(this.form.hasOwnProperty('D') && this.form.D.length>0){
      count++;
    }
    // if(this.form.hasOwnProperty('DM') && this.form.DM.length>0){
    //   count++;
    // }
    if(count<1)
      {
        this.api.toastrWarning('Please select atleast one option to save', 'Warning');
      }
      else
      {
        this.ref.close({status:true,data:this.form,index:this.index});
      }

  }

  console.log(count);

  
}

clearDate(){
  this.daterange=null;
  delete this.form['SD'];
  delete this.form['ED'];
}

}
