<nb-card>
    <nb-card-header>
        Action Required
    </nb-card-header>
    <nb-card-body>
        {{alertmsg}}

    </nb-card-body>
    <nb-card-footer>
        <button (click)="cancel()" class="btn btn-cancel float-left">{{btnfalse}}</button>
        <button (click)="confirm()" class="btn btn-confirm float-right">{{btntrue}}</button>
    </nb-card-footer>
</nb-card>