import { Component, Input } from '@angular/core';
import { Cell, DefaultEditor } from 'ng2-smart-table';

// @Component({
//   selector: 'ngx-object-edit',
//   templateUrl: './object-edit.component.html',
//   styleUrls: ['./object-edit.component.scss']
// })
@Component({
  template: `
    <div *ngIf="cell.isEditable()">
      <input type="text" [value]="value.name">
      <input type="number" [value]="value.id">
      <button (click)="onSave()">Save</button>
    </div>
    <div *ngIf="!cell.isEditable()">
      {{ value.name }} ({{ value.age }})
    </div>
  `,
})
export class ObjectEditComponent extends DefaultEditor {
  @Input() cell: Cell;

  value: any;

  ngOnInit() {
    console.log(`Check point 1'`, this.cell);
    
    this.value = Object.assign({}, this.cell.getValue());
    console.log(`Check point 1'`, this.value);
  }

  onSave() {
    this.cell.newValue = this.value;
    // this.cell.confirmEdit();
  }
}