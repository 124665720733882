<nb-card>
    <nb-card-header>
        Email id
    </nb-card-header>
    <nb-card-body>
        <div class="invalid" *ngIf="invalid">{{invalidMsg}}</div>
        <input type="text" [(ngModel)]="mailId" nbInput fullWidth>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton type="button" class="mx-2" status="success" (click)="close(true)">SEND</button>
        <button nbButton type="button" status="danger" (click)="close(false)">CANCEL</button>
    </nb-card-footer>
</nb-card>