<nb-card>
  <nb-card-header>
      Confirm with password
  </nb-card-header>
  <nb-card-body>
    <div class="invalidpass" *ngIf="invalidPassword">
      incorrect password !!
    </div>
      <div class="form-group">
          <label for="inputPassword">Password</label>
          <input #password type="password" class="form-control" id="inputPassword" placeholder="Password">
      </div>

      
  </nb-card-body>
  <nb-card-footer>
    <button (click)="close()" class="btn btn-danger float-left">Cancel</button>
    <button (click)="check()" class="btn btn-primary float-right">Confirm</button>
  </nb-card-footer>
</nb-card>