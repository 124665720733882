import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiServiceService } from '../../api-service.service';
import { ObjectEditComponent } from '../object-edit/object-edit.component';

@Component({
  selector: 'ngx-excel-import',
  templateUrl: './excel-import.component.html',
  styleUrls: ['./excel-import.component.scss']
})
export class ExcelImportComponent implements OnInit {

  constructor(private dialog: NbDialogRef<ExcelImportComponent>, private api: ApiServiceService) { }

  settings = {
    mode: 'inline',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      index: {
        title: '#',
        type: 'number',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        }
      }

    }
  }
  source: LocalDataSource = new LocalDataSource();

  @Input() data: any;
  @Input() type: any;
  ngOnInit(): void {

    console.log(`Check point 1'`, this.data.data);
    this.dataToTable(this.data.data);

  }


  dataToTable(data) {

    let datamodel = this.data.data[0];
    let keys = Object.keys(datamodel);
    let columns = {};
    keys.forEach(key => {
      // if key value is object type
      if (typeof datamodel[key] === 'object') {
        let valuePrepareFunction = (value, row, cell) => {
          return JSON.stringify(value);
        };
        let editor = {
          type: 'custom',
          component: ObjectEditComponent,
        };
        columns[key] = {
          title: key,
          type: 'string',
          valuePrepareFunction: valuePrepareFunction,
          editable: false
        }

      }
      else {
        columns[key] = {
          title: key,
          type: 'string'
        }
      }

    }
    );
    this.settings.columns = { ...this.settings.columns, ...columns };
    this.source.load(this.data.data);

  }



  import() {
    this.dialog.close(true);
  }

  close() {
    this.dialog.close(false);
  }

}
