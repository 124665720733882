import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() type;
  @Input() alertmsg;
  @Input() btntrue;
  @Input() btnfalse:any;
  constructor(private dialog:NbDialogRef<ConfirmComponent>) { }

  ngOnInit(): void {
    console.log("type", this.type);
    console.log("alertmsg", this.alertmsg);
    console.log("btnmsg", this.btntrue);
    
  }



  confirm(){
    this.dialog.close(true)
  }

  cancel(){
    this.dialog.close(false)
  }

}
